<template>
    <b-row>

        <h2 class="pl-1">{{ $t('roles_permission.titles.cardType') }}</h2>

        <!--  BEFORE TABLE  -->
        <div class="d-flex justify-content-between col-12">
            <b-col
                md="4"
                class="my-1 px-0"
            >
                <b-form-group
                    class="mb-0"
                >
                    <b-input-group size="md">
                        <b-form-input
                            id="filterInput"
                            v-model="searchQuery"
                            type="search"
                            :placeholder="$t('reusable.search_placeholder')"
                        />
                        <b-input-group-append>
                            <b-button
                                :disabled="!searchQuery"
                                @click="searchQuery = ''"
                            >
                                {{ $t('reusable.clear_btn') }}
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>

            <!--   CREATE   -->
            <div
                v-if="createPermission"
                class="my-1 float-right"
            >
                <modal-button
                    ripple="rgba(113, 102, 240, 0.15)"
                    size="lg"
                    name="modal-main"
                    :modal-title="$t('reusable.create')"
                    :modal-button="$t('reusable.create')"
                    @btn-emit="createCardType"
                    @open-emit="clearInputs"
                    variant="success"
                    ref="create-modal-button"
                >
                    <template v-slot:button @click="createCardType">{{ $t('reusable.create') }}</template>

                    <template v-slot:modal-body>
                        <ValidationObserver ref="validation-observer">
                            <div class="d-flex flex-wrap" style="gap:3rem">

                                <!--  Role  -->
                                <div class="col-12 card__box-shadow">
                                    <div class="row">

                                        <b-col cols="6">
                                            <ValidationProvider :name="$t('name')" rules="required"
                                                                v-slot="{errors}">
                                                <b-form-group
                                                    :label="$t('name')"
                                                    label-for="name"
                                                >
                                                    <b-form-input
                                                        v-model="cardType.name"
                                                        id="name"
                                                        size="lg"
                                                        :placeholder="$t('name')"
                                                    />
                                                </b-form-group>
                                                <span class="validation__red">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-col>

                                        <b-col cols="6">
                                            <ValidationProvider :name="$t('slug')" rules="required"
                                                                v-slot="{errors}">
                                                <b-form-group
                                                    :label="$t('slug')"
                                                    label-for="slug"
                                                >
                                                    <b-form-input
                                                        v-model="cardType.slug"
                                                        id="slug"
                                                        size="lg"
                                                        :placeholder="$t('slug')"
                                                    />
                                                </b-form-group>
                                                <span class="validation__red">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </b-col>

                                    </div>


                                </div>
                            </div>
                        </ValidationObserver>
                    </template>

                </modal-button>

            </div>
        </div>

        <!--  TABLE  -->
        <b-col cols="12">
            <b-table
                striped
                hover
                responsive
                sort-icon-left
                :busy="isBusy"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
            >

                <template v-slot:head()="data">
                    {{ $t(`${data.label}`) }}
                </template>

                <template #table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong class="ml-1">{{ $t('loading') }}</strong>
                    </div>
                </template>

                <template #cell(crud_row)="data">
                    <div class="d-flex float-right">

                        <!--   EDIT   -->
                        <modal-button
                            v-if="editPermission"
                            class="crud__btn"
                            ripple="rgba(113, 102, 240, 0.15)"
                            size="lg"
                            @btn-emit="updateCardTypeData(data.item.id, data.item)"
                            @open-emit="getOneCardType(data.item.id)"
                            @close-emit="clearInputs"
                            :name="`modal-edit-${data.item.id}`"
                            :modal-button="$t('reusable.save_btn')"
                            :id="data.item.id"
                            :modal-title="$t('reusable.modal_edit_btn')"
                            variant="flat-warning"
                            button-variant="warning"
                            ref="update-modal-button"
                        >
                            <template
                                v-slot:button
                            >
                                <feather-icon
                                    icon="Edit2Icon"
                                    size="21"
                                />
                            </template>


                            <template v-slot:modal-body>
                                <ValidationObserver ref="validation-observer">
                                    <div class="d-flex flex-wrap" style="gap:3rem">


                                        <!--  Role  -->

                                        <div class="col-12 card__box-shadow">
                                            <div class="row">


                                                <b-col cols="6">
                                                    <ValidationProvider :name="$t('name')" rules="required"
                                                                        v-slot="{errors}">
                                                        <b-form-group
                                                            :label="$t('name')"
                                                            label-for="nameEdit"
                                                        >
                                                            <b-form-input
                                                                v-model="cardType.name"
                                                                id="nameEdit"
                                                                size="lg"
                                                                :placeholder="$t('name')"
                                                            />
                                                        </b-form-group>
                                                        <span class="validation__red">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </b-col>

                                                <b-col cols="6">
                                                    <ValidationProvider :name="$t('slug')" rules="required"
                                                                        v-slot="{errors}">
                                                        <b-form-group
                                                            :label="$t('slug')"
                                                            label-for="slugEdit"
                                                        >
                                                            <b-form-input
                                                                v-model="cardType.slug"
                                                                id="slugEdit"
                                                                size="lg"
                                                                :placeholder="$t('slug')"
                                                            />
                                                        </b-form-group>

                                                        <span class="validation__red">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </b-col>

                                            </div>

                                        </div>

                                    </div>
                                </ValidationObserver>
                            </template>

                        </modal-button>


                        <!--   DELETE   -->
                        <modal-button
                            v-if="deletePermission"
                            class="crud__btn"
                            ripple="rgba(113, 102, 240, 0.15)"
                            size="sm"
                            :name="`modal-delete-${data.item.id}`"
                            :modal-button="$t('reusable.modal_delete_btn')"
                            :modal-title="$t('reusable.modal_delete_btn')"
                            variant="flat-danger"
                            :id="data.item.id"
                            ref="delete-modal"
                            @btn-emit="deleteCardType"
                        >
                            <template
                                v-slot:button
                            >
                                <feather-icon
                                    icon="Trash2Icon"
                                    size="21"
                                />
                            </template>

                            <template v-slot:modal-body>
                                <div class="my-2">
                                    {{ $t('modal.cardType_delete') }}
                                </div>
                            </template>

                        </modal-button>

                    </div>
                </template>
            </b-table>
        </b-col>

        <!--  PAGINATION  -->
        <b-col
            cols="12"
        >
            <b-pagination
                v-if="showPagination"
                v-model="pagination.current"
                :total-rows="pagination.totalItems"
                :per-page="pagination.perPage"
                align="center"
                size="sm"
                class="mt-0"
            ></b-pagination>
        </b-col>


    </b-row>
</template>

<script>
import {
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BSpinner,
    BCard,
    BFormTextarea,
} from 'bootstrap-vue'
import ModalButton from "@/views/ui/modals/ModalButton";
import api from '@/services/api'
import Ripple from 'vue-ripple-directive'
import ToastNotification from '@core/components/toastification/ToastNotification.vue'
import InfinityScrollSelect from "@/views/ui/infinity-scroll/InfinityScrollSelect";
import CardTypePermission from "@/permission/card-type";

export default {
    name: "CardTypes",
    components: {
        BTable,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BDropdown,
        BDropdownItem,
        BFormCheckbox,
        BSpinner,
        BCard,
        BFormTextarea,
        ModalButton,
        ToastNotification,
        InfinityScrollSelect,
    },
    directives: {
        Ripple
    },
    data() {
        return {
            cardType: {
                name: null,
                slug: null,
            },
            isBusy: false,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            searchQuery: this.$route.query.search,
            filterOn: [],
            fields: [
                {
                    key: 'id',
                    label: 'ID',
                },
                {
                    key: 'name',
                    label: 'name',
                },
                {
                    key: 'slug',
                    label: 'slug',
                },
                {
                    key: 'crud_row',
                    label: ' ',
                },
            ],
            items: [],
            loading: false,
            pagination: {
                current: 1,
                perPage: 10
            },
            createPermission: CardTypePermission.getCardTypeCreatePermission(),
            editPermission: CardTypePermission.getCardTypeEditPermission(),
            deletePermission: CardTypePermission.getCardTypeDeletePermission()
        }
    },
    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page
                this.getCardTypesList()
            },
            deep: true,
        },
        'pagination.current': {
            handler(page) {
                this.replaceRouter({
                    ...this.query,
                    page,
                })
            },
        },
        'pagination.perPage': {
            handler(perPage) {
                let page = this.pagination.current
                if (this.pagination.current > this.pagination.total) {
                    page = 1
                }
                this.replaceRouter({
                    ...this.query,
                    limit: perPage,
                    page: page
                })
            },
        },
        searchQuery: {
            handler(value) {
                if (value && value.length > 2) {
                    this.replaceRouter({
                        ...this.query,
                        search: value
                    })
                } else {
                    const hasSearchField = this.query.hasOwnProperty('search')
                    if (hasSearchField) {
                        delete this.query['search']
                        this.replaceRouter({
                            ...this.query,
                        })
                    }
                }
            },
        }
    },

    async created() {
        await this.getCardTypesList()
    },

    computed: {
        query() {
            return Object.assign({}, this.$route.query)
        },

        hasItems() {
            return this.items.length > 0
        },

        showPagination() {
            return this.hasItems && !this.isBusy
        },

        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => ({
                    text: f.label,
                    value: f.key,
                }))
        },
    },

    methods: {
        showToast(variant, text, icon) {
            this.$toast({
                component: ToastNotification,
                props: {
                    title: text,
                    icon: icon,
                    variant,
                },
            })
        },

        replaceRouter(query) {
            this.$router.replace({query})
                .catch(() => {
                })
        },

        clearInputs() {
            this.cardType = {
                name: null,
                slug: null,
            }
        },

        async getCardTypesList() {
            this.isBusy = true
            await api.cardType.getCardTypes(this.query)
                .then(res => {
                    this.items = res.data.items
                    this.pagination = res.data.pagination
                })
                .catch(() => {
                    this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
                })
                .finally(() => {
                    this.isBusy = false
                })
        },

        getOneCardType(id) {
            this.loading = true
            api.cardType.getCardType(id)
                .then(res => {
                    this.cardType = res.data
                })
                .catch((error) => {
                    if (error.response.data && error.response.data.errors) {
                        let errors = []
                        for (const [key, value] of Object.entries(error.response.data.errors)) {
                            errors.push(value[0])
                        }
                        errors.join(', ')
                        this.showToast('danger', errors[0], 'XIcon')
                    } else if (error.response.data && error.response.data.message) {
                        this.showToast('danger', error.response.data.message, 'XIcon')
                    } else {
                        this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },

        async createCardType() {
            const isValid = await this.$refs['validation-observer'].validate()
            if (isValid) {
                const data = this.cardType
                await api.cardType.createCardType(data)
                    .then(() => {
                        this.$refs['create-modal-button'].closeModal()
                        this.showToast('success', 'Успешно создано!', 'CheckIcon')
                    })
                    .catch((error) => {
                        if (error.response.data && error.response.data.errors) {
                            let errors = []
                            for (const [key, value] of Object.entries(error.response.data.errors)) {
                                errors.push(value[0])
                            }
                            errors.join(', ')
                            this.showToast('danger', errors[0], 'XIcon')
                        } else if (error.response.data && error.response.data.message) {
                            this.showToast('danger', error.response.data.message, 'XIcon')
                        } else {
                            this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
                        }
                    })
                    .finally(() => {
                        this.getCardTypesList()
                    })
            }
        },

        async updateCardTypeData(id) {
            const isValid = await this.$refs['validation-observer'].validate()
            if (isValid) {
                const data = this.cardType
                api.cardType.updateCardType(id, data)
                    .then(() => {
                        this.showToast('success', 'Успешно обновлено!', 'CheckIcon')
                        this.$refs['update-modal-button'].closeModal()
                        this.getCardTypesList()
                        this.clearInputs()
                    })
                    .catch((error) => {
                        if (error.response.data && error.response.data.errors) {
                            let errors = []
                            for (const [key, value] of Object.entries(error.response.data.errors)) {
                                errors.push(value[0])
                            }
                            errors.join(', ')
                            this.showToast('danger', errors[0], 'XIcon')
                        } else if (error.response.data && error.response.data.message) {
                            this.showToast('danger', error.response.data.message, 'XIcon')
                        } else {
                            this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
                        }
                    })
            }
        },

        async deleteCardType(id) {
            await api.cardType.deleteCardType(id)
                .then(() => {
                    this.$refs["delete-modal"].closeModal()
                    this.showToast('success', 'Успешно удалено!', 'CheckIcon')
                    this.getCardTypesList()
                })
                .catch((error) => {
                    if (error.response.data && error.response.data.errors) {
                        let errors = []
                        for (const [key, value] of Object.entries(error.response.data.errors)) {
                            errors.push(value[0])
                        }
                        errors.join(', ')
                        this.showToast('danger', errors[0], 'XIcon')
                    } else if (error.response.data && error.response.data.message) {
                        this.showToast('danger', error.response.data.message, 'XIcon')
                    } else {
                        this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
                    }
                })
        },
    },
}
</script>

<style lang="scss">


[dir] .dropdown-item {
    padding: 0;
}

.validation__red {
    color: red;
    font-size: 12px;
    display: block;
    margin-bottom: 1rem;
}

.form-group {
    margin-bottom: 6px;
}

.crud__btn .btn {
    padding: 0.6rem;
}

</style>
